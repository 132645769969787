<template lang="">
  <div class="flex justify-center items-center w-full mt-56">
    <p class="text-md font-bold text-gray-700 text-center">
      잠시만 기다려 주세요.
    </p>
  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapActions } from "vuex";

export default {
  created () {
    sessionStorage.removeItem('user')
    this.getSSOLoginInfo()
  },
  methods: {
    ...mapActions(["chgCurrentUser","chgCompanyGuid"]),
    async getSSOLoginInfo () {
      let reqObj = {}
      reqObj.target = `/user/ssoLogin/${this.$route.query.apiKey}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        const item = { 
          at: result.msg.retData.accessToken, 
          rt: result.msg.retData.refreshToken, 
          cs: result.msg.retData.checkSum, 
          ur: result.msg.retData.result
        }
        this.chgCurrentUser(item)
        this.chgCompanyGuid(result.msg.retData.result.companyGuid)
        this.$router.push({"name":"IndexPage"})
      }
      else {
        this.$router.push({"name":"IndexPage"})
      }
    }
  }
}
</script>
<style lang="">
  
</style>